import { $AboutWrapper } from "./styles"

function About() {
    return (
        <$AboutWrapper id="about">
            <div data-aos="zoom-in" data-aos-once="true">


                <h1>ABOUT</h1>
                <div>
                    <p>
                        I'm Ramta Hermez, based in Sweden. Diving into the world of frontend development as a junior explorer, I bring enthusiasm, fresh perspectives, and a hunger to level up. Navigating the realm of code with wide-eyed wonder, I'm on a quest for knowledge and excited to contribute to the next wave of digital innovation. Ready to turn challenges into stepping stones
                    </p>
                </div>
            </div>
        </$AboutWrapper>
    );
}

export default About;